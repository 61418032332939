<template>
  <b-list-group>
    <b-list-group-item
      class="d-flex justify-content-between align-items-center"
      :key="rank.value"
      v-for="rank in ranks">
        <span>
            <RankImage :rank="rank.value" />
            {{rank.text}}</span>
      <div>
        <span v-for="p in promotionsByRank(rank.value)"
              :key="p.id"
              @click="selectedPromotion(p)"
              class="mx-1">
          <PromotionButton :promotion="p"/>
        </span>
      </div>
      <div>
        <b-button @click="newPrePromotion(rank.value)">Provgradera</b-button>
        <b-button @click="newPromotion(rank.value)">Gradera</b-button>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import PromotionButton from '@/components/PromotionButton.vue'
import RankImage from '@/components/RankImage.vue'

export default {
  components: { PromotionButton, RankImage },
  props: {
    ranks: {
      type: Array
    },
    promotions: {
      type: Array
    },
  },
  emits: {
    newPromotion: (rank, type) => {
      return rank > 0 && rank < 20
        && ['promotion', 'prePromotion'].includes(type)
    }
  },
  methods: {
    newPromotion(rank) {
      this.$emit('new-promotion', rank, 'promotion')
    },
    newPrePromotion(rank) {
      this.$emit('new-promotion', rank, 'prePromotion')
    },
    selectedPromotion(promotion) {
      this.$emit('selected-promotion', promotion)
    },
    promotionsByRank(rank) {
      return this.promotions
        .filter(p => p.rank == rank)
        .sort((p1, p2) => Date.parse(p1.date) - Date.parse(p2.date))
    }
  }
}
</script>
