<template>
  <div>
    <b-form @submit.prevent="handleSubmit">
      <!-- Old friend or new member -->
      <div v-if="!newOrReturning">
          <p><strong>Anmälan till Uppsala ju-jutsuklubb för dig som är ny, eller haft träningsuppehåll sedan före 2021</strong>
              <br/>
              <em>För att börja i vår barnträning behöver du ha fyllt 9 år när terminen startar.</em>
          </p>
      <b-button-group>
      <b-button variant="primary" class="my-1" @click="setNewOrReturning('new')" data-new-member>Anmälan för nybörjare</b-button>
      </b-button-group>
      &nbsp;
      <b-button-group>
      <b-button variant="primary" class="my-1" @click="setNewOrReturning('returning')" data-returning-member>Jag är redan graderad i Ju-jutsu Kai</b-button>
      </b-button-group>
      <p>
          <em>Den som graderats i stilen Ju-jutsu Kai på annan klubb behöver ha ett giltigt monpass eller ju-jutsupass. Har eleven graderat på klubb som inte är ansluten till Ju-jutsu Kai (exempelvis Uppsala Budoklubb) väljer du 'Anmälan för nybörjare'. Är du osäker och har frågor kring detta, kontakta <a href="mailto:info@ujjk.se">info@ujjk.se</a>.</em>
      </p>
      <div class="me-2">
      <img src="../assets/monpass-14ar.jpg" width="120" class="mx-3" alt="Monpass">
      <img src="../assets/ju-jutsu-pass-267x360.jpg" width="120" alt="Ju-jutsupass">
      </div>
      </div>
      <h3 v-if="newOrReturning == 'new'">Anmälan för ny medlem</h3>
      <h3 v-if="newOrReturning == 'returning'">Anmälan för redan graderad i Ju-jutsu Kai</h3>

      <b-alert v-if="newOrReturning == 'new'" show>
          Träning för nybörjare startar vecka 4, 2025.
          Fyll i anmälan till vårens träning här nedanför,
          så skickar vi dig ett påminnelse-mail strax innan start!
      </b-alert>

      <!-- Subject of this registration -->
      <b-form-group
        v-if="newOrReturning"
        id="register-prospect-group-subject"
        ref="register-prospect-group-subject"
        label="Anmälan gäller*:"
        label-cols-sm="4"
        label-cols-lg="3"
        data-subject>
        <b-form-radio
          id="register-prospect-subject-myself"
          v-if="allowAdult"
          v-model="$v.prospect.subject.$model"
          :state="validateState('subject')"
          value="myself"
          :disabled="submitted"
          >Mig själv</b-form-radio>
        <b-form-radio
          id="register-prospect-subject-child"
          v-model="$v.prospect.subject.$model"
          :state="validateState('subject')"
          value="child"
          :disabled="submitted"
          >Mitt barn</b-form-radio>
        <b-form-invalid-feedback
          id="subject-live-feedback"
        >Du behöver berätta vem du anmäler</b-form-invalid-feedback>
      </b-form-group>

      <!-- Group -->
      <b-form-group
        v-if="newOrReturning"
        id="register-prospect-group-group"
        ref="register-prospect-group-group"
        label="Grupp*:"
        label-for="register-prospect-group"
        label-cols-sm="4"
        label-cols-lg="3"
        description="Här väljer du den grupp anmälan gäller. Den som redan är graderad i stilen Ju-jutsu Kai behöver ha ett giltigt monpass eller ju-jutsupass. Har eleven inte tidigare graderat inom Ju-jutsu Kai, klicka på 'Rensa formuläret' nedan och välj sen 'Anmälan för nybörjare'"
        >
        <b-form-select
          id="register-prospect-group"
          v-model="$v.prospect.group.$model"
          :state="validateState('group')"
          :disabled="submitted"
          data-group>
          <option v-if="prospect.subject == ''" disabled value="">Ange ovan om du anmäler dig själv eller ditt barn</option>
          <option v-else disabled value="">Välj grupp</option>
          <option v-if="newOrReturning == 'new' && prospect.subject == 'child'" value="mon-nyb">Mon nybörjare (9-12 år)</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'child'" value="mon-gul">Mon gul-vita</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'child'" value="mon-orange">Mon orange-vita</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'child'" value="mon-gron">Mon grön-vita</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'child'" value="mon-bla">Mon blå-vita</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'child'" value="mon-brun">Mon brun-vita</option>
          <option v-if="newOrReturning == 'new' && prospect.subject == 'child'" value="ung-nyb">Ungdomar nybörjare (13-15 år)</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'child'" value="ung-gul">Ungdomar fd. mon</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'child'" value="ung-gul">Ungdomar gul</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'child'" value="ung-orange">Ungdomar orange</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'child'" value="ung-gron">Ungdomar grön</option>
          <option v-if="newOrReturning == 'new' && prospect.subject == 'myself'" value="nyb">Vuxna nybörjare (16 år och äldre)</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'myself'" value="gul">Gul</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'myself'" value="orange">Orange</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'myself'" value="gron">Grön</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'myself'" value="bla">Blå</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'myself'" value="brun">Brun</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'myself'" value="1dan">1 dan</option>
          <option v-if="newOrReturning == 'returning' && prospect.subject == 'myself'" value="2danplus">2 till 8 dan</option>
        </b-form-select>
        <b-form-invalid-feedback
          id="group-live-feedback"
        >Du behöver välja grupp</b-form-invalid-feedback>
      </b-form-group>

      <!-- First name -->
      <b-form-group
        v-if="newOrReturning"
        id="register-prospect-group-firstname"
        ref="register-prospect-group-firstname"
        label="Förnamn*:"
        label-for="register-prospect-firstname"
        label-cols-sm="4"
        label-cols-lg="3"
        description="Den tränandes förnamn"
        >
        <b-form-input
          id="register-prospect-firstname"
          v-model.trim="prospect.firstName"
          :state="validateState('firstName')"
          @blur.native="$v.prospect.firstName.$touch()"
          :disabled="submitted"
          data-firstname></b-form-input>
        <b-form-invalid-feedback
          id="firstname-live-feedback"
        >Du behöver fylla i ett förnamn</b-form-invalid-feedback>
      </b-form-group>

      <!-- Last name -->
      <b-form-group
        v-if="newOrReturning"
        id="register-prospect-group-lastname"
        ref="register-prospect-group-lastname"
        label="Efternamn*:"
        label-for="register-prospect-lastname"
        label-cols-sm="4"
        label-cols-lg="3"
        description="Den tränandes efternamn"
        >
        <b-form-input
          id="register-prospect-lastname"
          v-model.trim="prospect.lastName"
          :state="validateState('lastName')"
          @blur.native="$v.prospect.lastName.$touch()"
          :disabled="submitted"
          data-lastname></b-form-input>
        <b-form-invalid-feedback
          id="lastname-live-feedback"
        >Du behöver fylla i ett efternamn</b-form-invalid-feedback>
      </b-form-group>

      <!-- Personal Id Number -->
      <b-form-group
        v-if="newOrReturning"
        id="register-prospect-group-personalIdNumber"
        ref="register-prospect-group-personalIdNumber"
        label="Personnummer*:"
        label-for="register-prospect-personalIdNumber"
        label-cols-sm="4"
        label-cols-lg="3"
        :description="personalIdNumberDescription()"
        >
        <b-form-input
          id="register-prospect-personalIdNumber"
          v-model.trim="prospect.personalIdNumber"
          :state="validateState('personalIdNumber')"
          @blur.native="$v.prospect.personalIdNumber.$touch()"
          @keypress="onlyNumbers($event)"
          type="number"
          placeholder="ÅÅÅÅMMDDXXXX"
          :disabled="submitted"
          data-personalidnumber></b-form-input>
        <b-form-invalid-feedback
          id="personalidnumber-live-feedback"
        >Du behöver fylla i ett giltigt personnummer (12 siffror)</b-form-invalid-feedback>
      </b-form-group>

      <!-- Comments -->
      <b-form-group
        v-if="newOrReturning"
        id="register-prospect-group-comments"
        ref="register-prospect-group-comments"
        label="Kommentarer eller övrig information:"
        label-for="register-prospect-comments"
        description="Här anger du om det är något särskilt som är viktigt att vi vet om för att eleven ska kunna delta i träningen."
        >
        <b-form-textarea
          id="register-prospect-comments"
          v-model.trim="prospect.comments"
          :state="validateState('comments')"
          @blur.native="$v.prospect.comments.$touch()"
          :disabled="submitted"
          data-comments></b-form-textarea>
      </b-form-group>

      <!-- Guardian first name -->
      <b-form-group
        v-if="needGuardianInfo && newOrReturning && prospect.subject == 'child'"
        id="register-prospect-group-guardianfirstname"
        ref="register-prospect-group-guardianfirstname"
        label="Målsmans förnamn:"
        label-for="register-prospect-guardianfirstname"
        label-cols-sm="4"
        label-cols-lg="3"
        >
        <b-form-input
          id="register-prospect-guardianfirstname"
          v-model.trim="prospect.guardianFirstName"
          :state="validateState('guardianFirstName')"
          @blur.native="$v.prospect.guardianFirstName.$touch()"
          :disabled="submitted"
          data-guardianfirstname></b-form-input>
        <b-form-invalid-feedback
          id="guardianfirstname-live-feedback"
        >Du behöver ange målsmans förnamn</b-form-invalid-feedback>
      </b-form-group>

      <!-- Guardian last name -->
      <b-form-group
        v-if="needGuardianInfo && newOrReturning && prospect.subject == 'child'"
        id="register-prospect-group-guardianlastname"
        ref="register-prospect-group-guardianlastname"
        label="Målsmans efternamn:"
        label-for="register-prospect-guardianlastname"
        label-cols-sm="4"
        label-cols-lg="3"
        >
        <b-form-input
          id="register-prospect-guardianlastname"
          v-model.trim="prospect.guardianLastName"
          :state="validateState('guardianLastName')"
          @blur.native="$v.prospect.guardianLastName.$touch()"
          :disabled="submitted"
          data-guardianlastname></b-form-input>
        <b-form-invalid-feedback
          id="guardianlastname-live-feedback"
        >Du behöver ange målsmans efternamn</b-form-invalid-feedback>
      </b-form-group>

      <!-- Email -->
      <b-form-group
        v-if="newOrReturning && user === undefined"
        id="register-prospect-group-email"
        ref="register-prospect-group-email"
        label="Epost*:"
        label-for="register-prospect-email"
        label-cols-sm="4"
        label-cols-lg="3"
        >
        <b-form-input
          id="register-prospect-email"
          v-model.trim="prospect.email"
          :state="validateState('email')"
          @blur.native="$v.prospect.email.$touch()"
          type="email"
          :disabled="submitted || !!this.user"
          data-email></b-form-input>
        <b-form-invalid-feedback
          id="email-live-feedback"
        >Du behöver ange en giltig epostadress</b-form-invalid-feedback>
      </b-form-group>

      <!-- Phone number -->
      <b-form-group
        v-if="newOrReturning"
        id="register-prospect-group-phonenumber"
        ref="register-prospect-group-phonenumber"
        label="Telefonnummer*:"
        label-for="register-prospect-phonenumber"
        label-cols-sm="4"
        label-cols-lg="3"
        >
        <b-form-input
          id="register-prospect-phonenumber"
          v-model="prospect.phoneNumber"
          :state="validateState('phoneNumber')"
          @blur.native="$v.prospect.phoneNumber.$touch()"
          @keypress="onlyNumbers($event)"
          type="tel"
          :disabled="submitted"
          data-phonenumber></b-form-input>
        <b-form-invalid-feedback
          id="phonenumber-live-feedback"
        >Du behöver ange ett telefonnummer</b-form-invalid-feedback>
      </b-form-group>

      <!-- GDPR -->
      <b-form-group
        v-if="newOrReturning"
        label="Insamlande av data*:"
        label-cols-sm="4"
        label-cols-lg="3"
          description="För att kunna delta i träningen på Uppsala ju-jutsuklubb behöver du ge oss ovanstående uppgifter. Vi behandlar dina uppgifter i enlighet med GDPR. I korthet delar vi vissa personuppgifter med Svenska ju-jutsuförbundet, Riksidrottsförbundet och Uppsala kommun, men inte med någon annan."
        >
        <b-form-checkbox
          id="register-prospect-consent"
          v-model="prospect.consent"
          :state="validateState('consent')"
          @blur.native="$v.prospect.consent.$touch()"
          :disabled="submitted"
          data-consent>Jag godkänner att Uppsala ju-jutsuklubb hanterar uppgifterna i denna anmälan</b-form-checkbox>
        <b-form-invalid-feedback
          id="concent-live-feedback"
        >Du behöver godkänna att vi behandlar dina personuppgifter</b-form-invalid-feedback>
      </b-form-group>

      <!-- Alerts -->
      <div>
      <b-alert variant="danger" v-model="showDismissableError" dismissible @dismissed="resetErrorMessage()">
        {{errorMessage}}
      </b-alert>

      <b-alert variant="success" :show="submitted">
        <h4 class="alert-heading">Tack {{ prospect.firstName }}!</h4>
        <p v-if="prospect.group == 'nyb-intresse'">Vi har nu tagit emot din intresseanmälan. Vi kommer att höra av oss till dig via epost så snart vi har möjlighet att ta emot nya nybörjare.</p>
        <p v-else>Din anmälan är nu registrerad. Glöm inte att betala medlemsavgift och träningsavgift senast terminsstart. Se <a href="http://ujjk.se/avgifter/">http://ujjk.se/avgifter</a> för mer information om avgifterna och hur du betalar.</p>
      </b-alert>
      </div>

      <!-- Reset form -->
      <b-button-toolbar class="float-right" v-if="newOrReturning">
      <b-button type="reset" @click="handleReset()" :disabled="this.submitting" class="m-1">
        Rensa formuläret
      </b-button>

      <!-- Submit -->
      <b-button type="submit" variant="primary" :disabled="this.submitting || this.submitted" class="m-1">
        <b-spinner v-if="submitting" small></b-spinner>
        Registrera anmälan
      </b-button>
      </b-button-toolbar>
    </b-form>
  </div>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import { email, maxLength, minLength, numeric, required } from 'vuelidate/lib/validators'
  import { db, serverTimestamp } from '@/firebaseConfig.js'
  import { validPersonalIdChecksum } from '@/utils/luhn.js'
  import { mapState } from 'vuex';
  import { addDoc, collection } from 'firebase/firestore'

  export default {
    mixins: [validationMixin],
    name: "RegisterProspect",
    props: {
      allowAdult: Boolean,
      needGuardianInfo: Boolean
    },
    data() {
      return {
        newOrReturning: '',
        prospect: {
          period: '',
          subject: '',
          firstName: '',
          lastName: '',
          personalIdNumber: '',
          group: undefined,
          comments: '',
          guardianFirstName: '',
          guardianLastName: '',
          phoneNumber: '',
          email: '',
          consent: false
        },
        submitting: false,
        submitted: false,
        showDismissableError: false,
        errorMessage: ''
      }
    },

    validations() {
      return {
        prospect: {
          period: { },
          subject: {required},
          firstName: {required},
          lastName: {required},
          personalIdNumber: {
            required,
            numeric,
            minLength: minLength(12),
            maxLength: maxLength(12),
            validPersonalIdChecksum
          },
          group: { required },
          comments: {},
          guardianFirstName: {},
          guardianLastName: {},
          phoneNumber: {
            required,
            numeric
          },
          email: {
            email
          },
          consent: {
            checked(val) {
              return val;
            }
          }
        }
      }
    },

    computed: {
      ...mapState('user', ['user']),
    },
    mounted() {
      if (this.allowAdult) {
        this.prospect.subject = 'child'
      }
    },
    methods: {
      onlyNumbers(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      isNewMember() {
        return this.newOrReturning == 'new'
      },
      isReturningMember() {
        return this.newOrReturning == 'returning'
      },
      setNewOrReturning(newOrReturning) {
        this.newOrReturning = newOrReturning
        this.prospect.period = this.isNewMember() ? 'vt-2025' : (this.isReturningMember() ? 'vt-2025' : '')
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.prospect[name];
        return $dirty ? !$error : null;
      },
      resetErrorMessage() {
        this.errorMessage = '';
      },
      personalIdNumberDescription() {
        var msg = 'Det fullständiga personnummret behövs för att vår försäkring ska kunna täcka eleven i händelse av skada, samt för att vi ska kunna söka bidrag hos Riksidrottsförbundet.';
        if (this.prospect.subject == 'child') {
          return 'OBS! Vi tar endast emot de elever som är 9 år fyllda innan terminsstart. ' + msg;
        }
        return msg;
      },

      handleSubmit() {
        this.$v.prospect.$touch();
        if (this.$v.prospect.$anyError) {
          return;
        }
        this.submitting = true
        this.prospect.created_at = serverTimestamp();

        // Set email and userId for logged in user
        console.log(`User is ${JSON.stringify(this.user)}`)
        if (this.user) {
          this.prospect.email = this.user.email
          this.prospect.userId = this.user.id
        }

        this.prospect.managedByGuardian = this.prospect.subject == 'child'

        // New adults will be assigned to the proper group
        if (this.newOrReturning == 'new' && this.prospect.subject == 'myself') {
          this.prospect.group = 'nyb'
        }

        addDoc(collection(db, 'prospects'), this.prospect)
          .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
            this.submitted = true
            this.handleReset()
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
            this.errorMessage = "Anmälan kunde inte skickas. Försök igen, eller kontakta info@ujjk.se."
            this.showDismissableError = true;
          });
        this.submitting = false
      },

      handleReset() {
        this.newOrReturning = '',
        this.prospect = {
          period: '',
          subject: this.allowAdult ? '' : 'child',
          firstName: '',
          lastName: '',
          personalIdNumber: '',
          group: undefined,
          comments: '',
          guardianFirstName: '',
          guardianLastName: '',
          phoneNumber: '',
          email: '',
          consent: false
        }
        this.submitted = false;
        this.$nextTick(() => {
          this.$v.prospect.$reset();
        });
      },
    }
  }

</script>

<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
</style>
