import { db } from '@/firebaseConfig.js'
import { firestoreAction } from 'vuexfire'

export default {
  namespaced: true,
  state: {
    groups: [],
    groupMembers: [],
    workouts: [],
    editedWorkout: null,
    instructors: [],
    attendees: [],
    prePromotions: [],
    selectedDate: null
  },
  getters: {
    currentGroupId(state, getters, rootState) {
      if (rootState.route.name === 'group') {
        return rootState.route.params.id
      } else {
        return null
      }
    },
    currentGroup(state, getters) {
      return state.groups.find(group => group.id === getters.currentGroupId)
    },
    isWorkoutBeingEdited(state) {
      return state.editedWorkout !== null
    },
    workoutDates(state) {
      return state.workouts.map(wo => wo.date)
    },
    currentWorkout(state, getters) {
      if (getters.workoutDates.includes(state.selectedDate)) {
        return state.workouts.find(wo => wo.date === state.selectedDate)
      } else {
        return null
      }
    }
  },
  mutations: {
    SET_CURRENT_WORKOUT(state, workout) {
      if (workout) {
        state.currentWorkout = workout;
      } else {
        state.currentWorkout = null;
      }
    },
    SET_EDITED_WORKOUT(state, workout) {
      if (workout) {
        state.editedWorkout = workout
      } else {
        state.editedWorkout = null;
      }
    },
    SET_SELECTED_DATE(state, date) {
      state.selectedDate = date
    }
  },
  actions: {
    bindGroups: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('groups', db.collection('groups').orderBy('sort'));
    }),
    unbindGroups: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('groups');
    }),
    bindWorkouts: firestoreAction(({ bindFirestoreRef, rootState }) => {
      const currentGroupId = rootState.route.params.id;
      return bindFirestoreRef('workouts', db.collection('workouts').where("groupId", "==", currentGroupId).orderBy('date', 'desc'));
    }),
    unbindWorkouts: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('workouts');
    }),
    bindGroupMembers: firestoreAction(({ bindFirestoreRef, rootState }) => {
      const currentGroupId = rootState.route.params.id;
      return bindFirestoreRef('groupMembers', db.collection('groupMembers').where("groupId", "==", currentGroupId).orderBy('name'));
    }),
    unbindGroupMembers: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('groupMembers');
    }),
    bindPrePromotions: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('prePromotions', db.collection('promotions').where('period', '==', 'vt-2025').orderBy('rank'));
    }),
    unbindPrePromotions: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('prePromotions');
    }),
    setSelectedDate({ commit }, date) {
      commit('SET_SELECTED_DATE', date)
    },
    async addFeeToGroupMember(context, groupMember) {
      if (!groupMember.isProspect) {
        groupMember.fees = []
        await db.collection('fees')
          .where("memberId", "==", groupMember.memberId).get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let fee = { ...doc.data(), feeId: doc.id }
              delete fee.memberId
              groupMember.fees.push(fee)
            })
          })
      }
      return groupMember
    },
    async addMemberToGroup(context, groupMember) {
      await context.dispatch('addFeeToGroupMember', groupMember)
      return db.collection('groupMembers').add(groupMember)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    async addInstructorToGroup(context, groupMember) {
      await context.dispatch('addFeeToGroupMember', groupMember)
      return db.collection('groupMembers').add(groupMember)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    setCurrentWorkout({ commit }, workout) {
      commit('SET_CURRENT_WORKOUT', workout)
    },
    setEditedWorkout({ commit }, workout) {
      commit('SET_EDITED_WORKOUT', workout)
    },
    saveEditedWorkout({dispatch, commit, state}) {
      if (state.editedWorkout.id) { // We are updating an existing workout
	dispatch('updateWorkout', state.editedWorkout)
          .then(() => {
            commit('SET_EDITED_WORKOUT', null)
          })
          .catch((error) => {
            console.error(error)
          })
      } else { // We are adding a new workout
        dispatch('addToWorkoutsAction', state.editedWorkout)
          .then(() => {
            commit('SET_EDITED_WORKOUT', null)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    addToWorkoutsAction(context, workout) {
      return db.collection('workouts').add(workout)
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    },
    updateWorkout(context, workout) {
      const workoutId = workout.id
      const docRef = db.collection('workouts').doc(workoutId)
      if (workout.attendees.length || workout.instructors.length) {
        delete workout.id
        return docRef.set(workout)
          .then(() => {
            console.log(`Workout with id ${workoutId} is updated`)
          })
          .catch((error) => {
            console.error('Error updating workout: ', error)
          })
      } else {
        docRef.delete()
      }
    }
  }
}
